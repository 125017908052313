<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
          >
            Frequently Asked Questions
          </p>
        </v-card>
      </v-card>
      <v-container :class="$vuetify.breakpoint.xsOnly ? 'pa-8' : 'px-8 py-71'">
        <v-card flat color="transparent" width="863" class="border-r-0 ma-auto">
          <div
            v-if="faqs !== null"
            class="font-16 faq-item line-h-26 font-weight--regular text-color-secondary-300 mt-30"
            v-html="faqs"
          ></div>
          <div
            class="font-16 line-h-26 font-weight--regular text-color-secondary-300 mt-30"
            v-else
          >
            No content is available.
          </div>
        </v-card>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";

export default {
  name: "FAQS",
  components: { TheHeader, TheFooter },
  computed: {
    faqs() {
      return this.$store.state.static.faqs;
    },
  },
  mounted() {
    this.$store.dispatch("static/FAQS", { key: "faq" });
  },
};
</script>
<style></style>
